<template>
	<div class="baseBody">
		<ScaleBox>
			<div class="main-wraper relative">
				<div id="container"
					style="width: 100%;height: 100%;position: absolute;z-index: 100;left: 0px;top: 100px;">
				</div>
				<div class="dateWeek">
					<DynamicDate></DynamicDate>
				</div>
				<div class="fullBox">
					<ScreenfullBox></ScreenfullBox>
				</div>
				<div class="baseTitleContent">
					<div class="baseTitleMid">
						<div class="top">
							<div class="topLine">
								<div class="title text-center base-font-56 text-white text-bold">
									{{title?title:'智慧安保分局数据看板'}}</div>
							</div>
						</div>
					</div>
				</div>
				<div class="flex align-center justify-between">
					<div>
						<div class="baseTitleLeft"></div>
					</div>
					<div>
						<div class="baseTitleRight"></div>
					</div>
				</div>
				<div style="z-index: 200;position: absolute;left: 0px;width: 20%">
					<LeftContent ref="leftRef"></LeftContent>
				</div>
				<div style="z-index: 200;position: absolute;right: 0px;width: 20%">
					<RightContent ref="rightRef"></RightContent>
				</div>

			</div>
		</ScaleBox>
		<el-dialog
		  :visible.sync="centerDialogVisible"
		  custom-class="customDialog"
		  top="40vh"
		  center>
		  <div v-html="centerDialogContent"></div>
		  <div class="text-center" style="margin-top:40px;opacity:0.8;"><i class="el-icon-circle-close text-white base-font-28 pointer" @click="centerDialogVisible=false"></i></div>
		</el-dialog>
	</div>
</template>

<script>
	import AMapLoader from "@amap/amap-jsapi-loader";
	import DynamicDate from "@/components/dynamicDate/index.vue";
	import ScreenfullBox from "@/components/screenfull/index.vue";
	import ScaleBox from "@/components/sacleBox/index.vue";
	import LeftContent from '@/views/fj/leftContent.vue';
	import RightContent from '@/views/fj/rightContent.vue';
	import {
		fjCenterData
	} from "@/api/api";
	export default {
		components: {
			LeftContent,
			RightContent,
			ScaleBox,
			DynamicDate,
			ScreenfullBox
		},
		data() {
			return {
				map: '',
				id: '',
				title: '',
				lineList: [],
				postionList: [],
				runMakerList: [],
				centerDialogVisible:false,
				centerDialogContent:'',
				
			}
		},
		mounted() {
			this.id = this.$route.query.id;
			console.log("中间id:" + this.id);
			this.changeData(this.id);
		},
		methods: {
			changeData(id) {
				let _self = this
				this.$refs.leftRef.changeData(id);
				this.$refs.rightRef.changeData(id);
				fjCenterData(id).then(res => {
					_self.title = res.data.name;
					if (_self.title.length < 9) {
						_self.title += "数据看板";
					} else if (_self.title.length < 11) {
						_self.title += "看板";
					}
					_self.lineList = res.data.list;
					_self.$nextTick(function() {
						_self.initAMap();
					});
				});
			},
			initAMap() {
				AMapLoader.load({
					key: '18997a286e73c2d94837b10f89516387', // 高德地图API Key
					version: '2.0', // 指定API版本
					plugins: ["AMap.ControlBar", "AMap.ToolBar"], // 需要使用的插件
					AMapUI: {
						version: '1.1',
						plugins: []
					}
				}).then((AMap) => {
					let longitude = '116.333926';
					let latitude = '39.997245';
					this.postionList = [];
					this.lineList.forEach((obj) => {
						obj.pointList.forEach((pointObj) => {
							this.postionList.push(pointObj);
						})
					})
					if (this.postionList.length > 0) {
						longitude = this.postionList[0].longitude;
						latitude = this.postionList[0].latitude;
					}
					console.log("longitude:" + longitude + ";latitude" + latitude);
					this.map = new AMap.Map('container', {
						rotateEnable: true,
						pitchEnable: true,
						zoom: 17,
						rotation: -15,
						viewMode: '3D', //开启3D视图,默认为关闭
						zooms: [2, 20],
						center: [longitude, latitude]
					});
					this.map.setMapStyle('amap://styles/darkblue');
					// var controlBar = new AMap.ControlBar({
					//   position:{
					//     right:'10px',
					//     top:'10px'
					//   }
					// });
					// controlBar.addTo(this.map);
					//
					// var toolBar = new AMap.ToolBar({
					//   position:{
					//     right:'40px',
					//     top:'110px'
					//   }
					// });
					// toolBar.addTo(this.map);
					this.setPostion(AMap);
					this.setLinerList(AMap);
					this.runMaker(AMap);

				}).catch(e => {
					console.log(e);
				});
			},
			setPostion(AMap) {
				let pointList = [];
				this.postionList.forEach((pointObj) => {
					let title = pointObj.name;
					if (pointObj.recordDateTime) {
						title += "<br/>到达时间：<br/>" + pointObj.recordDateTime;
					}
					// title =
					// 	"<div style='padding: 10px;font-size: 22px;border-radius: 10px;background-color: #406390;color: red;line-height: 28px;'>" +
					// 	title + "</div>"
					title =
						"<div >" +
						title + "</div>"
					let point = new AMap.Marker({
						position: [pointObj.longitude, pointObj.latitude],
						icon: new AMap.Icon({
							// 图标尺寸
							size: new AMap.Size(30, 46),
							// 图标的取图地址file:///E:/workspace/安保看板/rs-jszhab-sjkb/public/local.png
							image: 'https://ab.imfix.com/layuiadmin/images/point.png',
							// 图标所用图片大小
							imageSize: new AMap.Size(30, 46),
						}),
						offset: new AMap.Pixel(0, 0), //设置偏移量
						// label: {
						// 	direction: 'top',
						// 	content: title,
						// 	offset: new AMap.Pixel(0, -5),
						// },	
					});
					let _self=this
					point.on("click", function () {
						_self.centerDialogContent=_self.centerDialogContent='<div class="contentNei">'+title+'</div>'
						_self.centerDialogVisible=true
						console.log(title)
						
					  // alert("你点击了Marker");
					  
					});
					pointList.push(point);
					// 创建圆
					var circle = new AMap.Circle({
						center: [pointObj.longitude, pointObj.latitude],
						radius: pointObj.distance, //半径
						fillColor: '#8cc4fc', // 填充颜色
						fillOpacity: 1,
						borderWeight: 5,
						strokeColor: "#99999",
						zIndex: 50,
					})
					pointList.push(circle);
					// 缩放地图到合适的视野级别
					//this.map.setFitView([ circle ])
				})
				this.map.add(pointList);
			},
			setLinerList(AMap) {
				this.lineList.forEach((obj) => {
					this.setLiner(AMap, obj);
				})
			},
			setLiner(AMap, linerObj) {
				if (linerObj.recordList != null && linerObj.recordList.length > 0) {
					// 绘制轨迹
					let lineArr = [];
					linerObj.recordList.forEach((obj) => {
						lineArr.push([obj.longitude, obj.latitude])
					})
					this.runMakerList.push({
						num: 0,
						workerNames: linerObj.workerNames,
						recordList: linerObj.recordList,
						maker: null
					});
					// 绘制轨迹
					new AMap.Polyline({
						map: this.map,
						path: lineArr,
						showDir: true,
						strokeColor: linerObj.sceneDescription, //线颜色
						// strokeOpacity: 1,     //线透明度
						strokeWeight: 6, //线宽
						// strokeStyle: "solid"  //线样式
					});

				}
			},
			runMaker(AMap) {
				setTimeout(() => {
					let makerList = [];
					this.runMakerList.forEach((obj, index) => {
						//console.log("runMaker:"+this.runMakerList.length+";"+obj.num);
						if (obj.maker != null) {
							this.map.remove(obj.maker);
						}
						let maker = this.getRunMaker(AMap, obj.workerNames, obj.recordList[obj.num]);
						obj.maker = maker;
						makerList.push(maker);
						obj.num += 1;
						if (obj.num >= obj.recordList.length) {
							obj.num = 0;
						}
						this.runMakerList.splice(index, 1, obj);
					})
					this.map.add(makerList);
					this.runMaker(AMap);
				}, 2000)
			},
			getRunMaker(AMap, workerName, obj) {
				//设置maker
				let title = "巡逻人：" + workerName;
				if (obj.recordDateTime) {
					title += "<br/>时间：<br/>" + obj.recordDateTime;
				}
				// title =
				// 	"<div style='padding: 10px;font-size: 22px;border-radius: 10px;background-color: #008080;color: #ffffff;line-height: 28px;'>" +
				// 	title + "</div>"
				title =
					"<div >" +
					title + "</div>"
				let makerPoint = new AMap.Marker({
					position: [obj.longitude, obj.latitude],
					icon: new AMap.Icon({
						// 图标尺寸
						size: new AMap.Size(46, 46),
						// 图标的取图地址1
						image: 'https://ab.imfix.com/layuiadmin/images/iconab.png',
						// 图标所用图片大小
						imageSize: new AMap.Size(46, 46),
					}),
					offset: new AMap.Pixel(0, 0), //设置偏移量
					// label: {
					// 	direction: 'top',
					// 	content: title,
					// 	offset: new AMap.Pixel(0, -5),
					// }
				});
				let _self=this
				makerPoint.on("click", function () {
					_self.centerDialogContent='<div class="contentGreen">'+title+'</div>'
					_self.centerDialogVisible=true
					console.log(title)
					
				  // alert("你点击了Marker");
				  
				});

				return makerPoint;
			}
		}
	}
</script>

<style lang="scss">
	.baseBody {
		width: 100%;
		height: 100vh;
		overflow: hidden;
		position: relative;
		font-size: 14px;
		background: #000208;
		position: relative;
		.customDialog{
			width:300px;height:188px;box-shadow: none;
			background:  url('../../assets/images/tcbg.png') center no-repeat;
			background-size: 100% 100%;
			.el-dialog__header{display: none;}
			.el-dialog__body{padding:10px 13px 0 14px;}
			.contentNei{
				height:150px;font-size:20px;color:#FFFFFF;padding: 30px 20px;line-height: 28px;
				background: -webkit-linear-gradient(top, rgba(12,40,87,0.9), rgba(0,96,175,0.8)); /* Safari 5.1 - 6.0 */
				background: -o-linear-gradient(top, rgba(12,40,87,0.9), rgba(0,96,175,0.8)); /* Opera 11.1 - 12.0 */
				background: -moz-linear-gradient(top, rgba(12,40,87,0.9), rgba(0,96,175,0.8)); /* Firefox 3.6 - 15 */
				background: linear-gradient(to bottom, rgba(12,40,87,0.9), rgba(0,96,175,0.8)); /* 标准的语法 */
			}
			.contentGreen{
				height:150px;font-size:20px;color:#FFFFFF;padding: 30px 20px;line-height: 28px;
				background: -webkit-linear-gradient(top, rgba(2,31,71,0.8), rgba(0,188,158,0.7)); /* Safari 5.1 - 6.0 */
				background: -o-linear-gradient(top, rgba(2,31,71,0.8), rgba(0,188,158,0.7)); /* Opera 11.1 - 12.0 */
				background: -moz-linear-gradient(top, rgba(2,31,71,0.8), rgba(0,188,158,0.7)); /* Firefox 3.6 - 15 */
				background: linear-gradient(to bottom, rgba(2,31,71,0.8), rgba(0,188,158,0.7)); /* 标准的语法 */
			}
		}
		.main-wraper {
			user-select: none;
			width: 1920px;
			height: 1080px;
			margin: 0 auto;
			background: #02081a url('../../assets/images/sytBg2.png') center no-repeat;
			background-size: cover;
			box-sizing: border-box;
		}

	}

	.dateWeek {
		position: absolute;
		top: 0;
		left: 0;
		width: 200px;
		height: 50px;
		color: #ffffff;
		z-index: 200;
	}

	.fullBox {
		position: absolute;
		top: 0;
		right: 0;
		width: 200px;
		height: 50px;
		color: #ffffff;
		z-index: 999;
	}

	.nyBaseHeadTop {
		width: 100%;
		height: 80px;
		background: url('../../assets/images/titleBg.png') top center no-repeat;
		background-size: auto 80px
	}

	.base-headTit {
		font-size: 30px;
		font-weight: bold;
		box-sizing: border-box;
		padding-top: 24px;
	}

	.baseTitleLeft {
		width: 644px;
		height: 55px;
		background: url('../../assets/images/titleLeft.png') top right no-repeat;
		background-size: auto 55px
	}

	.baseTitleRight {
		width: 644px;
		height: 55px;
		background: url('../../assets/images/titleRight.png') top left no-repeat;
		background-size: auto 55px
	}

	.baseBody .baseTitleContent {
		width: 100%;
		height: 100px;
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		z-index: 200;
	}

	.baseBody .baseTitleContent .baseTitleMid {
		width: 659px;
		height: 100px;
		margin: 0 auto;
		background: url('../../assets/images/headtitileBg3.png') top left no-repeat;
		background-size: auto 100px
	}

	.baseBody .baseTitleContent .baseTitleMid .top {
		width: 659px;
		height: 100px;
		margin: 0 auto;
		background: url('../../assets/images/headtitileBg2.png') top left no-repeat;
		background-size: auto 100px
	}

	.baseBody .baseTitleContent .baseTitleMid .topLine {
		width: 659px;
		height: 100px;
		margin: 0 auto;
		background: url('../../assets/images/titleBg4.png') top left no-repeat;
		background-size: auto 100px
	}

	.baseBody .baseTitleContent .baseTitleMid .title {
		width: 502px;
		height: 70px;
		margin: 0 auto;
		font-size: 34px;
		font-weight: bold;
		line-height: 70px;
		background: url('../../assets/images/headtitileBg1.png') top left no-repeat;
		background-size: auto 70px
	}
</style>